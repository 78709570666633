/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function(){
    $('[data-nav-trigger]').on('click', function(){
        $(this).toggleClass('active');
        $('[data-nav]').toggleClass('visible').height($(window).height());

        var label = $('#menuLabel').text();
        if (label === 'MENU') {
            $('#menuLabel').text('CLOSE');
        } else {
            $('#menuLabel').text('MENU');
        }
    });

    if ($(window).width() <= 1024) {
        $('[data-submenu-trigger]').on('click', function(){
            $(this).toggleClass('active');
            var target = $(this).attr('data-submenu-trigger');
            $('[data-submenu="' + target + '"]').toggleClass('visible');
            return false;
        });
        $('[data-fmenu-trigger]').on('click', function(){
            $(this).toggleClass('active');
            var target = $(this).attr('data-fmenu-trigger');
            $('[data-fmenu="' + target + '"]').toggleClass('visible');
            return false;
        });
        $('[data-languageTab-trigger]').on('click', function(){
            $(this).toggleClass('active');
            var target = $(this).attr('href');
            $(target).toggleClass('visible');
            return false;
        });

    }

    $('[data-gnav-close]').on('click', function() {
        $('[data-submenu]').css('visibility', 'hidden');
        $('[data-submenu]').css('opacity', '0');
        $('[data-submenu]').css('pointer-events', 'none');
        $('.p-gnav-list-item').addClass('js-close');
        return false;
    });

    $(window).on('mousemove', function(){
        $('.js-close [data-submenu]').css('visibility', '');
        $('.js-close [data-submenu]').css('opacity', '');
        $('.js-close [data-submenu]').css('pointer-events', '');
        $('.js-close.p-gnav-list-item').removeClass('js-close');
    });

    $(window).on('scroll', function(){
        $('header').css('left', -$(window).scrollLeft());
    });
});
